<template>
  <div class="income-charts-box" :id="pieChartsId + 'box'">
    <!-- 有数据 -->
    <div v-if="!loading" class="echarts-pie">
      <div class="chart-area">
        <div :id="pieChartsId" class="pie-charts" ref="chartsBox"></div>
        <div class="pie-text">
          <slot></slot>
        </div>
      </div>
      <!-- 图例 -->
      <div class="pie-legend">
        <div class="legend-item" v-for="(pie, index) in seriesData" :key="index">
          <span class="legend-icon" :style="`backgroundColor:${pie.color}`"></span>
          <span class="legend-name" :style="`color:${pie.color}`">{{ pie.name }}</span>
          <span class="value">{{ `${pie.value ? `${pie.value || 0}%` : '0%'}` }}</span>
        </div>
      </div>
    </div>
    <div v-else class="pie-loading">
      <div class="ykc-loading bar-loading">
        <div class="k-line k-line11-1"></div>
        <div class="k-line k-line11-2"></div>
        <div class="k-line k-line11-3"></div>
        <div class="k-line k-line11-4"></div>
        <div class="k-line k-line11-5"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import * as echarts from 'echarts';

  export default {
    name: 'pie-charts',
    props: {
      data: {
        // 数据源
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        loading: true,
        myObserver: null,
        myChart: null,
        // 图形id
        pieChartsId: `pieChartsId-${Math.random().toString(36).substring(3, 8)}`,
        pieData: this.data || {},
        seriesData: [],
      };
    },
    mounted() {
      this.$nextTick(() => {
        // 在div发生改变时调整echarts的尺寸
        this.myObserver = new ResizeObserver(() => {
          setTimeout(() => {
            // eslint-disable-next-line no-unused-expressions
            this.myChart && this.myChart.resize();
          });
        });
        this.myObserver.observe(document.getElementById(`${this.pieChartsId}box`));
      });
    },
    /**
     * 实时监测
     */
    watch: {
      data: {
        immediate: true,
        deep: true,
        handler(newV, oldV) {
          this.initlegend();
          //  先判断isData，然后在dom执行后绘图
          if (newV === oldV) {
            this.$nextTick(() => {
              this.initlegend();
              this.drawPie();
            });
            return;
          }
          this.loading = false;
          this.$nextTick(() => {
            if (newV && !this.loading) {
              this.initlegend();
              this.drawPie();
            }
          });
        },
      },
    },
    created() {
      console.log('data', this.data);
      // this.drawPie();
    },
    methods: {
      /**
       * 初始图例
       */
      initlegend() {
        this.seriesData = [
          {
            name: '电费',
            value: this.data.chargeFee || 0,
            color: 'rgba(0, 138, 245, 1)',
          },
          {
            name: '服务费',
            value: this.data.serviceFee || 0,
            color: 'rgba(205, 144, 22, 1)',
          },
        ];
        console.log(
          'seriesData xxx',
          this.seriesData.map(item => item.value)
        );
      },
      drawPie() {
        let myChart = echarts.getInstanceByDom(document.getElementById(this.pieChartsId));
        if (!myChart) {
          // 基于准备好的dom，初始化echarts实例
          myChart = echarts.init(document.getElementById(this.pieChartsId));
        }
        console.log(JSON.stringify(this.data), 'data');
        // 绘制图表
        myChart.setOption(
          {
            series: [
              {
                type: 'pie',
                zlevel: 1,
                radius: ['47%', '63%'],
                center: ['40%', '50%'],
                color: ['rgba(0, 138, 245, 1)', 'rgba(205, 144, 22, 1)'],
                itemStyle: {
                  borderWidth: 3,
                  borderColor: '#011128',
                },
                label: {
                  show: false,
                },
                data: [
                  {
                    value: this.data.chargeFee || 0,
                    name: '电费',
                  },
                  {
                    value: this.data.serviceFee || 0,
                    name: '服务费',
                  },
                ],
                labelLine: {
                  show: false,
                },
              },
            ],
          },
          true
        );
        this.myChart = myChart;
      },
    },
    beforeDestroy() {
      // eslint-disable-next-line no-unused-expressions
      this.myObserver &&
        document.getElementById(`${this.pieChartsId}box`) &&
        this.myObserver.unobserve(document.getElementById(`${this.pieChartsId}box`));
      // eslint-disable-next-line no-unused-expressions
      this.myChart && this.myChart.dispose();
    },
  };
</script>
<style lang="scss">
  .income-charts-box {
    width: 100%;
    height: 100%;
    position: relative;
    .pie-loading {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -60px;
      margin-top: -6px;
    }
  }
  .income-charts-box .echarts-pie {
    display: flex;
    align-items: center;
    .chart-area {
      width: 50%;
      height: 100%;
      position: relative;
    }
    .pie-charts {
      width: 180px;
      height: 150px;
    }

    .pie-text {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 10px;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .pie-center {
        text-align: center;
        padding-top: 10px;
        div {
          color: #00f5ff;
          font-size: 12px;
          font-family: 'TiHei';
          span.unit {
            font-family: initial;
          }
        }
        div:last-child {
          color: #fff;
          font-family: 'PuHuiTiB';
        }
      }
    }
    .pie-legend {
      width: 100%;
      padding-left: 5px;
      .legend-item {
        display: flex;
        align-items: center;
        span {
          margin-left: 6px;
          margin-bottom: 12px;
        }
        .legend-icon {
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 100%;
        }
        .legend-name {
          font-size: 14px;
          // font-family: YouSheBiaoTiHei;
          color: #c1c028;
          line-height: 18px;
        }
        .value {
          font-size: 14px;
          // font-family: AppleSystemUIFont;
          color: #f2f2f2;
          line-height: 17px;
        }
      }
    }
  }
</style>
