<!--
 * @Author: Daokai Huo
 * @Date: 2022-04-01 16:39:34
 * @LastEditTime: 2022-04-12 15:27:39
 * @Description: 3D环形饼图
-->
<template>
  <div class="pie-charts-wrap">
    <div v-if="!loading" id="PieCharts"></div>
    <div v-else class="bar-loading">
      <div class="ykc-loading bar-loading">
        <div class="k-line k-line11-1"></div>
        <div class="k-line k-line11-2"></div>
        <div class="k-line k-line11-3"></div>
        <div class="k-line k-line11-4"></div>
        <div class="k-line k-line11-5"></div>
      </div>
    </div>
    <div class="pie-wrap" v-if="!loading">
      <div>
        <span>充电</span>
        {{ data.chargingPer }}%
      </div>
      <div>
        <span>空闲</span>
        {{ data.leisurePer }}%
      </div>
      <div>
        <span>异常</span>
        {{ data.exceptionPer }}%
      </div>
    </div>
  </div>
</template>

<script>
  import * as echarts from 'echarts';
  import 'echarts-gl';

  export default {
    name: 'YkcPieCharts',
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        loading: true,
        optionData: [],
      };
    },
    watch: {
      data: {
        // 首次绑定是否执行handle
        immediate: true,
        deep: true,
        handler(newV) {
          this.loading = false;
          this.$nextTick(() => {
            if (newV && !this.loading) {
              this.initPie();
            }
          });
        },
      },
    },
    mounted() {
      this.$nextTick(() => {
        // 在div发生改变时调整echarts的尺寸
        this.myObserver = new ResizeObserver(() => {
          setTimeout(() => {
            if (this.myChart) {
              this.myChart.resize();
            }
          });
        });
        this.myObserver.observe(document.getElementById('PieCharts'));
      });
    },
    created() {},
    methods: {
      initPie() {
        const {
          exceptionPer: abnormalPercent,
          leisurePer: freePercent,
          chargingPer: chargingPercent,
        } = this.data;
        this.optionData = [
          {
            name: '异常',
            value: abnormalPercent,
            itemStyle: {
              color: '#FF8C32',
            },
          },
          {
            name: '空闲',
            value: freePercent,
            itemStyle: {
              color: '#29AAFF',
            },
          },
          {
            name: '充电',
            value: chargingPercent,
            itemStyle: {
              color: '#00FFF4',
            },
          },
        ];
        // 构建3d饼状图
        const myChart = echarts.init(document.getElementById('PieCharts'));
        // 传入数据生成 option
        this.option = this.getPie3D(this.optionData, 0.8);
        myChart.setOption(this.option);
        // 是否需要label指引线，如果要就添加一个透明的2d饼状图并调整角度使得labelLine和3d的饼状图对齐，并再次setOption
        this.option.series.push({
          name: 'pie2d',
          type: 'pie',
          labelLine: {
            show: false,
            length: 5,
            length2: 5,
          },
          startAngle: -10, // 起始角度，支持范围[0, 360]。
          clockwise: false, // 饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
          radius: ['20%', '50%'],
          center: ['10%', '10%'],
          data: this.optionData,
          itemStyle: {
            opacity: 0,
          },
        });
        myChart.setOption(this.option);
        this.myChart = myChart;
        this.bindListen(myChart);
      },

      getPie3D(pieData, internalDiameterRatio) {
        // internalDiameterRatio:透明的空心占比
        const that = this;
        const series = [];
        let sumValue = 0;
        let startValue = 0;
        let endValue = 0;
        let legendBfb = [];
        const k = 1 - internalDiameterRatio;
        pieData.sort((a, b) => {
          return b.value - a.value;
        });
        // 为每一个饼图数据，生成一个 series-surface 配置
        for (let i = 0; i < pieData.length; i++) {
          sumValue += pieData[i].value;
          const seriesItem = {
            name: typeof pieData[i].name === 'undefined' ? `series${i}` : pieData[i].name,
            type: 'surface',
            parametric: true,
            wireframe: {
              show: false,
            },
            pieData: pieData[i],
            pieStatus: {
              selected: false,
              hovered: false,
              k,
            },
            center: ['10%', '50%'],
          };

          if (typeof pieData[i].itemStyle !== 'undefined') {
            const itemStyle = {};
            // eslint-disable-next-line no-unused-expressions
            typeof pieData[i].itemStyle.color !== 'undefined'
              ? (itemStyle.color = pieData[i].itemStyle.color)
              : null;
            // eslint-disable-next-line no-unused-expressions
            typeof pieData[i].itemStyle.opacity !== 'undefined'
              ? (itemStyle.opacity = pieData[i].itemStyle.opacity)
              : null;
            seriesItem.itemStyle = itemStyle;
          }
          series.push(seriesItem);
        }

        // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
        // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
        legendBfb = [];
        for (let i = 0; i < series.length; i++) {
          endValue = startValue + series[i].pieData.value;
          series[i].pieData.startRatio = startValue / sumValue;
          series[i].pieData.endRatio = endValue / sumValue;
          series[i].parametricEquation = this.getParametricEquation(
            series[i].pieData.startRatio,
            series[i].pieData.endRatio,
            false,
            false,
            k,
            series[i].pieData.value
          );
          startValue = endValue;
          const bfb = that.fomatFloat(series[i].pieData.value / sumValue, 4);
          legendBfb.push({
            name: series[i].name,
            value: bfb,
          });
        }
        const boxHeight = this.getHeight3D(series, 15); // 通过传参设定3d饼/环的高度，26代表26px
        // 准备待返回的配置项，把准备好的 legendData、series 传入。
        const option = {
          labelLine: {
            show: true,
            lineStyle: {
              color: '#7BC0CB',
            },
          },
          label: {
            show: true,
            position: 'outside',
            rich: {
              b: {
                color: '#7BC0CB',
                fontSize: 12,
                lineHeight: 20,
              },
              c: {
                fontSize: 16,
              },
            },
            formatter: '{b|{b} \n}{c|{c}}{b|  亩}',
          },
          tooltip: {
            show: false,
            // trigger: 'axis',
            backgroundColor: 'transparent',
            borderWidth: 0,
            // padding: 0,
            extraCssText: 'box-shadow: none;',
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: 'none',
            },
            position(point, params, dom, rect, size) {
              // 显示位置
              return [point[0] - 5, point[1] - size.contentSize[1] + 5];
            },
            // eslint-disable-next-line consistent-return
            formatter: params => {
              // console.log('params', params);
              if (params.seriesName !== 'mouseoutSeries' && params.seriesName !== 'pie2d') {
                // const bfb = (
                //   (option.series[params.seriesIndex].pieData.endRatio -
                //     option.series[params.seriesIndex].pieData.startRatio) *
                //   100
                // ).toFixed(2);
                // return (
                //   `${params.seriesName}<br/>` +
                //   `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>` +
                //   `${0}%`
                // );
                const html = `
                  <div class="pie-wrap">
                    <div><span>充电</span>${this.optionData[2].value}%</div>
                    <div><span>空闲</span>${this.optionData[1].value}%</div>
                    <div><span>异常</span>${this.optionData[0].value}%</div>
                  </div>
                `;
                return html;
              }
            },
          },
          xAxis3D: {
            min: -1,
            max: 1,
          },
          yAxis3D: {
            min: -1,
            max: 1,
          },
          zAxis3D: {
            min: -1,
            max: 1,
          },
          grid3D: {
            show: false,
            // 圆环的高度
            boxHeight,
            viewControl: {
              // 3d效果可以放大、旋转等，请自己去查看官方配置
              alpha: 40, // 角度
              distance: 145, // 调整视角到主体的距离，类似调整zoom
              rotateSensitivity: 0, // 设置为0无法旋转
              zoomSensitivity: 0, // 设置为0无法缩放
              panSensitivity: 0, // 设置为0无法平移
              autoRotate: true, // 自动旋转
            },
          },
          series,
        };
        return option;
      },

      // 获取3d丙图的最高扇区的高度
      getHeight3D(series, height) {
        series.sort((a, b) => {
          return b.pieData.value - a.pieData.value;
        });
        return (height * 25) / series[0].pieData.value;
      },

      // 生成扇形的曲面参数方程，用于 series-surface.parametricEquation
      getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, h) {
        // 计算
        const midRatio = (startRatio + endRatio) / 2;
        const startRadian = startRatio * Math.PI * 2;
        const endRadian = endRatio * Math.PI * 2;
        const midRadian = midRatio * Math.PI * 2;
        // 如果只有一个扇形，则不实现选中效果。
        if (startRatio === 0 && endRatio === 1) {
          isSelected = false;
        }
        // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
        k = typeof k !== 'undefined' ? k : 1 / 3;
        // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
        const offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
        const offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;
        // 计算高亮效果的放大比例（未高亮，则比例为 1）
        const hoverRate = isHovered ? 1.05 : 1;
        // 返回曲面参数方程
        return {
          u: {
            min: -Math.PI,
            max: Math.PI * 3,
            step: Math.PI / 32,
          },
          v: {
            min: 0,
            max: Math.PI * 2,
            step: Math.PI / 20,
          },
          x(u, v) {
            if (u < startRadian) {
              return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
            }
            if (u > endRadian) {
              return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
            }
            return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
          },
          y(u, v) {
            if (u < startRadian) {
              return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
            }
            if (u > endRadian) {
              return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
            }
            return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
          },
          z(u, v) {
            if (u < -Math.PI * 0.5) {
              return Math.sin(u);
            }
            if (u > Math.PI * 2.5) {
              return Math.sin(u) * h * 0.1;
            }
            return Math.sin(v) > 0 ? 1 * h * 0.1 : -1;
          },
        };
      },

      fomatFloat(num, n) {
        let f = parseFloat(num);
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(f)) {
          return false;
        }
        // eslint-disable-next-line no-restricted-properties
        f = Math.round(num * 10 ** n) / 10 ** n; // n 幂
        let s = f.toString();
        let rs = s.indexOf('.');
        // 判定如果是整数，增加小数点再补0
        if (rs < 0) {
          rs = s.length;
          s += '.';
        }
        while (s.length <= rs + n) {
          s += '0';
        }
        return s;
      },

      bindListen(myChart) {
        // 监听鼠标事件，实现饼图选中效果（单选），近似实现高亮（放大）效果。
        const that = this;
        // let selectedIndex = '';
        let hoveredIndex = '';
        // // 监听点击事件，实现选中效果（单选）
        // myChart.on('click', params => {
        //   // 从 option.series 中读取重新渲染扇形所需的参数，将是否选中取反。
        //   const isSelected = !that.option.series[params.seriesIndex].pieStatus.selected;
        //   const isHovered = that.option.series[params.seriesIndex].pieStatus.hovered;
        //   const { k } = that.option.series[params.seriesIndex].pieStatus;
        //   const { startRatio } = that.option.series[params.seriesIndex].pieData;
        //   const { endRatio } = that.option.series[params.seriesIndex].pieData;
        //   // 如果之前选中过其他扇形，将其取消选中（对 option 更新）
        //   if (selectedIndex !== '' && selectedIndex !== params.seriesIndex) {
        //     that.option.series[selectedIndex].parametricEquation = that.getParametricEquation(
        //       that.option.series[selectedIndex].pieData.startRatio,
        //       that.option.series[selectedIndex].pieData.endRatio,
        //       false,
        //       false,
        //       k,
        //       that.option.series[selectedIndex].pieData.value
        //     );
        //     that.option.series[selectedIndex].pieStatus.selected = false;
        //   }
        //   // 对当前点击的扇形，执行选中/取消选中操作（对 option 更新）
        //   that.option.series[params.seriesIndex].parametricEquation = that.getParametricEquation(
        //     startRatio,
        //     endRatio,
        //     isSelected,
        //     isHovered,
        //     k,
        //     that.option.series[params.seriesIndex].pieData.value
        //   );
        //   that.option.series[params.seriesIndex].pieStatus.selected = isSelected;
        //   // 如果本次是选中操作，记录上次选中的扇形对应的系列号 seriesIndex
        //   // eslint-disable-next-line no-unused-expressions
        //   isSelected ? (selectedIndex = params.seriesIndex) : null;
        //   // 使用更新后的 option，渲染图表
        //   myChart.setOption(that.option);
        // });
        // 监听 mouseover，近似实现高亮（放大）效果
        // myChart.on('mouseover', params => {
        //   // 准备重新渲染扇形所需的参数
        //   let isSelected;
        //   let isHovered;
        //   let startRatio;
        //   let endRatio;
        //   let k;
        //   // 如果触发 mouseover 的扇形当前已高亮，则不做操作
        //   if (hoveredIndex === params.seriesIndex) {
        //     // 否则进行高亮及必要的取消高亮操作
        //   } else {
        //     // 如果当前有高亮的扇形，取消其高亮状态（对 option 更新）
        //     if (hoveredIndex !== '') {
        //       // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 false。
        //       isSelected = that.option.series[hoveredIndex].pieStatus.selected;
        //       isHovered = false;
        //       startRatio = that.option.series[hoveredIndex].pieData.startRatio;
        //       endRatio = that.option.series[hoveredIndex].pieData.endRatio;
        //       k = that.option.series[hoveredIndex].pieStatus.k;
        //       // 对当前点击的扇形，执行取消高亮操作（对 option 更新）
        //       that.option.series[hoveredIndex].parametricEquation = that.getParametricEquation(
        //         startRatio,
        //         endRatio,
        //         isSelected,
        //         isHovered,
        //         k,
        //         that.option.series[hoveredIndex].pieData.value
        //       );
        //       that.option.series[hoveredIndex].pieStatus.hovered = isHovered;
        //       // 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
        //       hoveredIndex = '';
        //     }
        //     // 如果触发 mouseover 的扇形不是透明圆环，将其高亮（对 option 更新）
        //     if (params.seriesName !== 'mouseoutSeries' && params.seriesName !== 'pie2d') {
        //       // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
        //       isSelected = that.option.series[params.seriesIndex].pieStatus.selected;
        //       isHovered = true;
        //       startRatio = that.option.series[params.seriesIndex].pieData.startRatio;
        //       endRatio = that.option.series[params.seriesIndex].pieData.endRatio;
        //       k = that.option.series[params.seriesIndex].pieStatus.k;
        //       // 对当前点击的扇形，执行高亮操作（对 option 更新）
        //       that.option.series[params.seriesIndex].parametricEquation =
        //         that.getParametricEquation(
        //           startRatio,
        //           endRatio,
        //           isSelected,
        //           isHovered,
        //           k,
        //           that.option.series[params.seriesIndex].pieData.value + 5
        //         );
        //       that.option.series[params.seriesIndex].pieStatus.hovered = isHovered;
        //       // 记录上次高亮的扇形对应的系列号 seriesIndex
        //       hoveredIndex = params.seriesIndex;
        //     }
        //     // 使用更新后的 option，渲染图表
        //     myChart.setOption(that.option);
        //   }
        // });
        // 修正取消高亮失败的 bug
        myChart.on('globalout', () => {
          // 准备重新渲染扇形所需的参数
          let isSelected;
          let isHovered;
          let startRatio;
          let endRatio;
          let k;
          if (hoveredIndex !== '') {
            // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
            isSelected = that.option.series[hoveredIndex].pieStatus.selected;
            isHovered = false;
            k = that.option.series[hoveredIndex].pieStatus.k;
            startRatio = that.option.series[hoveredIndex].pieData.startRatio;
            endRatio = that.option.series[hoveredIndex].pieData.endRatio;
            // 对当前点击的扇形，执行取消高亮操作（对 option 更新）
            that.option.series[hoveredIndex].parametricEquation = that.getParametricEquation(
              startRatio,
              endRatio,
              isSelected,
              isHovered,
              k,
              that.option.series[hoveredIndex].pieData.value
            );
            that.option.series[hoveredIndex].pieStatus.hovered = isHovered;
            // 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
            hoveredIndex = '';
          }
          // 使用更新后的 option，渲染图表
          myChart.setOption(that.option);
        });
      },
    },
    deactivated() {
      if (this.myObserver && document.getElementById('PieCharts')) {
        this.myObserver.unobserve(document.getElementById('PieCharts'));
      }
      if (this.myChart) {
        this.myChart.dispose();
      }
    },
    beforeDestroy() {
      if (this.myObserver && document.getElementById('PieCharts')) {
        this.myObserver.unobserve(document.getElementById('PieCharts'));
      }
      if (this.myChart) {
        this.myChart.dispose();
      }
    },
  };
</script>
<style lang="scss">
  .pie-charts-wrap {
    width: 100%;
    position: relative;

    .bar-loading {
      margin-top: 60px;
    }
    .pie-wrap {
      position: absolute;
      top: 23px;
      right: 60px;
      width: 154px;
      height: 114px;
      background: url('../images/pie-charts-wrap.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      div {
        width: 110px;
        font-size: 12px;
        color: #29aaff;
        padding-top: 5px;
        span {
          margin-right: 12px;
          margin-left: 30px;
          display: inline-block;
          position: relative;
        }
        span:before {
          position: absolute;
          left: -10px;
          top: 6px;
          display: block;
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background: #29aaff;
        }
      }
      div:first-child {
        color: #00fff4;
        span:before {
          background: #00fff4;
        }
      }
      div:last-child {
        color: #ff8c32;
        span:before {
          background: #ff8c32;
        }
      }
    }
  }
  #PieCharts {
    width: 70%;
    height: 150px;
    padding-top: 20px;
    background: transparent;
    > div,
    canvas {
      background: transparent;
    }
    > div:last-child {
      .pie-wrap {
        width: 154px;
        height: 114px;
        background: url('../images/pie-charts-wrap.png') no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        div {
          width: 110px;
          font-size: 12px;
          color: #29aaff;
          padding-top: 2px;
          span {
            margin-right: 12px;
            margin-left: 30px;
            display: inline-block;
            position: relative;
          }
          span:before {
            position: absolute;
            left: -10px;
            top: 6px;
            display: block;
            content: '';
            width: 5px;
            height: 5px;
            border-radius: 100%;
            background: #29aaff;
          }
        }
        div:first-child {
          color: #00fff4;
          span:before {
            background: #00fff4;
          }
        }
        div:last-child {
          color: #ff8c32;
          span:before {
            background: #ff8c32;
          }
        }
      }
    }
  }
</style>
