<!--
 * @Author: Daokai Huo
 * @Date: 2022-04-01 16:39:34
 * @LastEditTime: 2022-04-12 17:37:24
 * @Description: 玫瑰图表
-->
<template>
  <div id="rose-charts">
    <div v-if="!isShowLoad" id="charts-bar"></div>
    <div v-else class="bar-loading">
      <div class="ykc-loading bar-loading">
        <div class="k-line k-line11-1"></div>
        <div class="k-line k-line11-2"></div>
        <div class="k-line k-line11-3"></div>
        <div class="k-line k-line11-4"></div>
        <div class="k-line k-line11-5"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as echarts from 'echarts';

  export default {
    name: 'YkcRoseCharts',
    props: {
      // 图表数据
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        isShowLoad: true,
      };
    },
    watch: {
      data: {
        // 首次绑定是否执行handle
        immediate: true,
        deep: true,
        handler(val, oldval) {
          if (JSON.stringify(val) === JSON.stringify(oldval)) {
            return;
          }
          this.loading = false;
          this.$nextTick(() => {
            if (val && !this.loading) {
              this.drawRose();
            }
          });
        },
      },
    },
    mounted() {
      this.$nextTick(() => {
        // 在div发生改变时调整echarts的尺寸
        this.myObserver = new ResizeObserver(() => {
          setTimeout(() => {
            if (this.myChart) {
              this.myChart.resize();
            }
          });
        });
        this.myObserver.observe(document.getElementById('rose-charts'));
      });
    },
    created() {},
    /**
     * 进入缓存页面钩子
     */
    activated() {
      this.drawRose();
    },
    methods: {
      /**
       * 初始化图表
       */
      drawRose() {
        const { standbyCountRatio, chargingCountRatio, disChargingCountRatio } = this.data;
        // 基于准备好的dom，初始化echarts实例
        const myChart = echarts.init(document.getElementById('rose-charts'));
        const option = {
          grid: {
            bottom: 150,
            left: 100,
            right: '10%',
          },
          series: [
            // 主要展示层的
            {
              radius: ['36%', '65%'],
              center: ['50%', '50%'],
              type: 'pie',
              roseType: true,
              label: {
                normal: {
                  show: true,
                  formatter: item => {
                    return `${item.name} ${item.value}%`;
                  },
                  fontSize: 12,
                  position: 'outside',
                },
                emphasis: {
                  show: true,
                },
              },
              labelLine: {
                normal: {
                  show: true,
                  length: 10,
                },
                emphasis: {
                  show: true,
                },
              },
              data: [
                {
                  name: '待机',
                  value: standbyCountRatio,
                  itemStyle: {
                    normal: {
                      color: '#FF8C32',
                    },
                  },
                  label: {
                    color: '#FF8C32',
                  },
                },
                {
                  name: '放电',
                  value: disChargingCountRatio,
                  itemStyle: {
                    normal: {
                      color: '#29AAFF',
                    },
                  },
                  label: {
                    color: '#29AAFF',
                  },
                },
                {
                  name: '充电',
                  value: chargingCountRatio,
                  itemStyle: {
                    normal: {
                      color: '#00FFF4',
                    },
                  },
                  label: {
                    color: '#00FFF4',
                  },
                },
              ],
            },
          ],
        };
        // 绘制图表
        myChart.setOption(option);
        this.myChart = myChart;
      },
    },
    deactivated() {
      if (this.myObserver && document.getElementById('rose-charts')) {
        this.myObserver.unobserve(document.getElementById('rose-charts'));
      }
      if (this.myChart) {
        this.myChart.dispose();
      }
    },
    beforeDestroy() {
      if (this.myObserver && document.getElementById('rose-charts')) {
        this.myObserver.unobserve(document.getElementById('rose-charts'));
      }
      if (this.myChart) {
        this.myChart.dispose();
      }
    },
  };
</script>
<style lang="scss">
  #rose-charts {
    width: 100%;
    height: 180px;
    .bar-loading {
      margin-top: 60px;
    }
  }
</style>
