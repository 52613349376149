<template>
  <OverviewLayout ref="Layout">
    <div class="overview-wrap">
      <ScreenHeader />
      <div class="map-wrap">
        <overview-map :mapData="stationMap"></overview-map>
        <div class="grain"></div>
      </div>
      <div class="overview-left">
        <div>
          <!-- 累计数据 start -->
          <div class="public-wrap summary-data">
            <div class="title">累计数据</div>
            <div style="height: 6px"></div>
            <div class="in-title">充电度数(度)</div>
            <div class="income" v-if="stationIncome.chargeTotal">
              <span v-for="(item, index) in zeroize(stationIncome.chargeTotal, 8)" :key="index">
                {{ item }}
              </span>
            </div>
            <div class="in-title" style="margin-top: 10px">充电金额(元)</div>
            <div class="income" v-if="stationIncome.incomeTotal">
              <span v-for="(item, index) in zeroize(stationIncome.incomeTotal, 8)" :key="index">
                {{ item }}
              </span>
            </div>
            <div class="order-wrap">
              <div class="order-income">
                <div class="order-total">
                  <ykc-count-to
                    :duration="2000"
                    :endVal="Number(stationIncome.userQuantity)"></ykc-count-to>
                </div>
                <div>充电用户数(个)</div>
              </div>
              <div class="order-income">
                <div class="order-total">
                  <ykc-count-to
                    :duration="2000"
                    :endVal="Number(stationIncome.chargeTimes)"></ykc-count-to>
                </div>
                <div>充电次数(次)</div>
              </div>
            </div>
          </div>
          <!-- 累计数据 end -->
          <!-- 节能减排 start -->
          <div class="public-wrap cere-data">
            <div class="title">节能减排</div>
            <div class="wrap">
              <div class="co2">
                <div class="icon"></div>
                <div class="value">
                  <span class="num">{{ cereInfo.co2 }}</span>
                  <span class="unit">摩尔</span>
                </div>
                <div class="label">CO₂减排</div>
              </div>
              <div class="trees">
                <div class="icon"></div>
                <div class="value">
                  <span class="num">{{ cereInfo.trees }}</span>
                  <span class="unit">棵</span>
                </div>
                <div class="label">植树数量</div>
              </div>
            </div>
          </div>
          <!-- 节能减排 end -->
          <!-- 7天充电统计 start -->
          <div class="public-wrap">
            <div class="title">7天充电统计</div>
            <div class="line-charts">
              <ykc-line-charts :data="weeklyStatistics"></ykc-line-charts>
            </div>
          </div>
          <!-- 7天充电统计 end -->
        </div>
      </div>
      <div class="overview-right">
        <div>
          <!-- 充电终端监控 start -->
          <div class="public-wrap">
            <div class="title">充电终端监控</div>
            <div class="total-wrap">
              <div class="total-item">
                <div class="subTitle">电站数量</div>
                <div class="total">
                  <ykc-count-to
                    :duration="2000"
                    :endVal="Number(terminalMonitoringInfo.stationTotal)"></ykc-count-to>
                </div>
              </div>
              <div class="total-item">
                <div class="subTitle">终端数量</div>
                <div class="total">
                  <ykc-count-to
                    :duration="2000"
                    :endVal="Number(terminalMonitoringInfo.gunTotal)"></ykc-count-to>
                </div>
              </div>
            </div>
            <div class="pie-charts">
              <ykc-charts-pie :data="terminalMonitoringInfo"></ykc-charts-pie>
            </div>
          </div>
          <!-- 充电终端监控 end -->
          <!-- 今日数据 start -->
          <div class="public-wrap today-data">
            <div class="title">今日数据</div>
            <div class="wrap">
              <div class="charge">
                <div class="label">
                  <div class="icon"></div>
                  <div class="title">充电度数（度）</div>
                </div>
                <div class="split-line"></div>
                <div class="value">{{ todayData.charge }}</div>
              </div>
              <div class="amount">
                <div class="label">
                  <div class="icon"></div>
                  <div class="title">充电金额（元）</div>
                </div>
                <div class="split-line"></div>
                <div class="value">{{ todayData.amount }}</div>
              </div>
            </div>
          </div>
          <!-- 今日数据 end -->
          <!-- 昨日数据汇总 start -->
          <div class="public-wrap yesterday-data">
            <div class="title">昨日数据汇总</div>
            <div class="compare-wrap">
              <div class="amount">
                <div class="icon"></div>
                <div class="compare" :class="calculateCompareClass(yesterdaySummary.compareAmount)">
                  <span class="text">日环比</span>
                  <span class="compare-value">{{ yesterdaySummary.compareAmount }}%</span>
                </div>
                <div class="value">{{ yesterdaySummary.amount }}</div>
                <div class="label">充电金额（元）</div>
              </div>
              <div class="charge">
                <div class="icon"></div>
                <div class="compare" :class="calculateCompareClass(yesterdaySummary.compareCharge)">
                  <span class="text">日环比</span>
                  <span class="compare-value">{{ yesterdaySummary.compareCharge }}%</span>
                </div>
                <div class="value">{{ yesterdaySummary.charge }}</div>
                <div class="label">充电度数（度）</div>
              </div>
            </div>
            <div class="rank-wrap" v-if="yesterdaySummary.topStationInfoList.length > 0">
              <div
                class="top-rank"
                v-for="(item, index) of yesterdaySummary.topStationInfoList"
                :key="item.stationId">
                <div class="icon">{{ index + 1 }}</div>
                <div class="value">
                  <div class="station">{{ item.stationName }}</div>
                  <div class="charge">
                    {{ item.chargedPower }}
                    <span>度</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 昨日数据汇总 end -->
        </div>
      </div>
      <div class="overview-bottom">
        <!-- <div class="jfpg">
          <jfpg-pie-charts :data="todayTotalDuration">
            <div class="pie-center">
              <div>今日总时长</div>
              <div>
                <span>(h)</span>
              </div>
              <div>
                <ykc-count-to
                  :decimals="0"
                  :duration="2000"
                  :endVal="todayTotalDuration.total"></ykc-count-to>
              </div>
            </div>
          </jfpg-pie-charts>
        </div> -->
        <div class="order">
          <order-pie-charts :data="todayTotalOrder">
            <div class="pie-center">
              <div>今日订单总数</div>
              <div>
                <span>(笔)</span>
              </div>
              <div>
                <ykc-count-to
                  :decimals="0"
                  :duration="2000"
                  :endVal="todayTotalOrder.total"></ykc-count-to>
              </div>
            </div>
          </order-pie-charts>
        </div>
        <div class="income">
          <income-pie-charts :data="todayTotalIncome">
            <div class="pie-center">
              <div>今日收入</div>
              <div>
                <span>(元)</span>
              </div>
              <div>
                <ykc-count-to
                  :decimals="0"
                  :duration="2000"
                  :endVal="todayTotalIncome.total"></ykc-count-to>
              </div>
            </div>
          </income-pie-charts>
        </div>
      </div>
    </div>
  </OverviewLayout>
</template>
<script>
  import windowResizeMixin from '@/components/ykc-ui/utils/window-resize-mixin';
  import overviewMap from './OverviewMap.vue';
  // eslint-disable-next-line
  import YkcChartsBar from './ChartsBar.vue';
  // eslint-disable-next-line
  import YkcRoseCharts from './RoseCharts.vue';
  import YkcLineCharts from './LineCharts.vue';
  import YkcChartsPie from './PieCharts.vue';
  import YkcCountTo from './YkcCountTo.vue';
  // eslint-disable-next-line
  import ScreenHeader from './screen-header.vue';
  import JfpgPieCharts from './jfpg-pie-charts.vue';
  import OrderPieCharts from './order-pie-charts.vue';
  import IncomePieCharts from './income-pie-charts.vue';
  import OverviewLayout from '@/layouts/OverviewLayout.vue';
  import { reconciliationApi } from '@/service/apis';

  console.log('reconciliationApi', reconciliationApi);

  export default {
    name: 'Overview',
    mixins: [windowResizeMixin],
    components: {
      OverviewLayout,
      // eslint-disable-next-line
      IncomePieCharts,
      // eslint-disable-next-line
      OrderPieCharts,
      // eslint-disable-next-line
      JfpgPieCharts,
      // eslint-disable-next-line
      ScreenHeader,
      overviewMap,
      // eslint-disable-next-line
      YkcLineCharts,
      YkcChartsPie,
      YkcCountTo,
    },
    data() {
      return {
        // 地图数据
        stationMap: [],

        // 累计数据
        stationIncome: {
          chargeTotal: 0,
          incomeTotal: 0,
          userQuantity: 0,
          chargeTimes: 0,
        },
        // 节能减排
        cereInfo: {
          co2: 0,
          trees: 0,
        },
        // 7天充电统计
        weeklyStatistics: [
          // {
          //   date: '2023-02-25',
          //   orderQuantity: '3368',
          //   chargedPower: '89739',
          // },
        ],

        // 充电终端监控
        terminalMonitoringInfo: {
          stationTotal: 0,
          gunTotal: 0,
          chargingPer: 0,
          leisurePer: 0,
          exceptionPer: 0,
        },
        // 今日数据
        todayData: {
          charge: 0,
          amount: 0,
        },
        // 昨日数据汇总
        yesterdaySummary: {
          compareAmount: 0,
          amount: 0,
          compareCharge: 0,
          charge: 0,
          topStationInfoList: [],
        },

        // 今日总时长
        todayTotalDuration: {
          total: 0,
        },
        // 今日订单总数
        todayTotalOrder: {
          realtime: 0,
          history: 0,
          abnormal: 0,
          total: 0,
        },
        // 今日收入
        todayTotalIncome: {
          // 电费
          chargeFee: 0,
          // 服务费
          serviceFee: 0,
          total: 0,
        },
      };
    },
    created() {
      this.queryScreenOverview();
      this.findStationStatistics();
      this.findStationMap();
      this.queryTodayOrderInfoByOne();
      this.oneMinTime = setInterval(() => {
        this.queryScreenOverview();
        this.findStationStatistics();
        // this.findStationMap();
        this.queryTodayOrderInfoByOne();
      }, 60 * 1000);
    },
    methods: {
      handleDomResize(...args) {
        console.log('handleDomResize', args);
      },
      /**
       * 补零处理
       */
      zeroize(val, len) {
        return String(val).padStart(len, '0').split('');
      },
      calculateSize() {
        this.setScale();
      },
      calculateCompareClass(val) {
        const value = Number(val || 0);
        if (value > 0) return 'greater';
        if (value < 0) return 'less';
        return 'equal';
      },
      setScale() {
        let scale = window.innerWidth / 1920;
        const winw = window.innerWidth;
        const winh = window.innerHeight;
        this.winHeight = winh;
        if (winw <= 1366) {
          scale = '0.71';
        }
        if (document.querySelector('.overview-wrap')) {
          document.querySelector('.overview-wrap').style.transform = `scale(${scale})`;
        }
        if (this.$refs.Layout) {
          this.$nextTick(this.$refs.Layout.update);
        }
      },
      queryScreenOverview() {
        reconciliationApi.queryScreenOverview({}).then(res => {
          console.log('queryScreenOverview', res);
          if (res.accumulativeTotal) {
            // 累计数据
            this.stationIncome.chargeTotal = Number(res.accumulativeTotal.chargedPower || 0) || 0;
            this.stationIncome.incomeTotal = Number(res.accumulativeTotal.chargedAmount || 0) || 0;
            this.stationIncome.userQuantity =
              Number(res.accumulativeTotal.chargedUserTotal || 0) || 0;
            this.stationIncome.chargeTimes = Number(res.accumulativeTotal.chargedTimes || 0) || 0;
            // 节能减排
            this.cereInfo.co2 = Number(res.accumulativeTotal.co2 || 0) || 0;
            this.cereInfo.trees = Number(res.accumulativeTotal.treePlant || 0) || 0;
          }
          // 7天充电统计
          if (res.chargeStatistics && Array.isArray(res.chargeStatistics)) {
            this.weeklyStatistics = res.chargeStatistics.map(item => {
              item.chargedPower = Number(item.chargedPower || 0) || 0;
              item.incomeAmount = Number(item.incomeAmount || 0) || 0;
              return item;
            });
          }
          // 今日数据
          if (res.todayData) {
            this.todayData.charge = Number(res.todayData.todayChargedPower || 0) || 0;
            this.todayData.amount = Number(res.todayData.todayChargedAmount || 0) || 0;
          }
          // 昨日数据
          if (res.yesterdayDataSum) {
            this.yesterdaySummary.compareAmount =
              Number(res.yesterdayDataSum.amountChain || 0) || 0;
            this.yesterdaySummary.amount =
              Number(res.yesterdayDataSum.yesterdayChargedAmount || 0) || 0;
            this.yesterdaySummary.compareCharge = Number(res.yesterdayDataSum.powerChain || 0) || 0;
            this.yesterdaySummary.charge =
              Number(res.yesterdayDataSum.yesterdayChargedPower || 0) || 0;
            this.yesterdaySummary.topStationInfoList =
              res.yesterdayDataSum.topStationInfoList || [];
          }
          if (res.todayOrderAndIncomeInfo) {
            this.todayTotalOrder.total =
              Number(res.todayOrderAndIncomeInfo.todayOrderNum || 0) || 0;
            this.todayTotalOrder.realtime =
              Number(res.todayOrderAndIncomeInfo.todayRealTimeOrderNum || 0) || 0;
            this.todayTotalOrder.history =
              Number(res.todayOrderAndIncomeInfo.todayHiseOrderNum || 0) || 0;
            this.todayTotalOrder.abnormal =
              Number(res.todayOrderAndIncomeInfo.todayexceptOrderNum || 0) || 0;

            this.todayTotalIncome.total =
              Number(res.todayOrderAndIncomeInfo.todayChargedAmount || 0) || 0;
            this.todayTotalIncome.chargeFee =
              Number(res.todayOrderAndIncomeInfo.todayChargeFeePer || 0) || 0;
            this.todayTotalIncome.serviceFee =
              Number(res.todayOrderAndIncomeInfo.todayServiceFeePer || 0) || 0;
          }
        });
      },
      findStationStatistics() {
        reconciliationApi.findStationStatistics({}).then(res => {
          console.log('findStationStatistics', res);
          this.terminalMonitoringInfo.stationTotal = Number(res.stationTotal || 0) || 0;
          this.terminalMonitoringInfo.gunTotal = Number(res.gunTotal || 0) || 0;
          this.terminalMonitoringInfo.chargingPer = Number(res.chargingPer || 0) || 0;
          this.terminalMonitoringInfo.leisurePer = Number(res.leisurePer || 0) || 0;
          this.terminalMonitoringInfo.exceptionPer = Number(res.exceptionPer || 0) || 0;
        });
      },
      findStationMap() {
        reconciliationApi.findStationMap({}).then(res => {
          console.log('findStationMap', res);
          this.stationMap = (res || []).map(item => {
            const keys = [
              'longitude',
              'latitude',
              'directAllCount',
              'directleisure',
              'exchangeAllCount',
              'exchangeleisure',
            ];
            keys.forEach(key => {
              item[key] = Number(item[key] || 0) || 0;
            });
            console.log(item);
            return {
              ...item,
            };
          });
        });
      },
      queryTodayOrderInfoByOne() {
        reconciliationApi.queryTodayOrderInfoByOne({}).then(res => {
          console.log('queryTodayOrderInfoByOne', res);
        });
      },
    },
    mounted() {
      this.setScale();

      // addResizeListener(this.$el, this.setScale);
      // this.$on('hook:beforeDestroy', () => {
      //   removeResizeListener(this.$el, this.setScale);
      // });
    },
    beforeDestroy() {
      // 清空定时器
      clearInterval(this.oneMinTime);
    },
  };
</script>
<style lang="scss">
  @import './index.scss';
  .overview-layout {
    .ykc-emp-header {
      color: inherit;
    }
  }
  .overview-wrap {
    width: 100%;
    max-width: 1920px;
    min-width: 1920px;
    height: 969px;
    min-height: 969px;
    transform-origin: 0 0;
    position: relative;
    .map-wrap {
      width: 100%;
      height: 1080px;
      min-height: 969px;
      max-height: 1080px;
      position: relative;
      .grain {
        width: 100%;
        height: 129px;
        background: url('~../images/grain@2x.png') no-repeat;
        background-size: 100%;
        // position: fixed;
        position: absolute;
        bottom: 0;
        z-index: 2;
      }
      .grain::before {
        display: block;
        content: '';
        width: 100%;
        height: 140px;
        position: absolute;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
        z-index: 2;
      }
    }
    .map-wrap::after,
    .map-wrap::before {
      display: block;
      content: '';
      width: 280px;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 1;
    }
    .map-wrap::after {
      left: 0;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
    }
    .map-wrap::before {
      right: 0;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
    }
    // 公共背景
    .public-wrap {
      width: 396px;
      height: auto;
      background: url('~../images/charge-wrap@2x.png') no-repeat;
      background-size: 100% 100%;
      .title {
        color: #00fff4;
        font-size: 13px;
        text-align: center;
        padding-top: 1px;
      }
    }
    // 左侧样式
    .overview-left,
    .overview-right {
      min-height: 895px;
      height: 1010px;
      position: absolute;
      top: 0;
      margin-top: 70px;
      z-index: 3;
      > div {
        position: relative;
        top: 20px;
        height: 100%;
      }
      .public-wrap {
        margin-top: 25px;
        // 累计数据
        &.summary-data {
          height: 350px;
          .in-title {
            margin-left: 26px;
            color: #fff;
            font-size: 12px;
            height: 20px;
            font-weight: 500;
            line-height: 20px;
          }
          .income {
            margin-top: 10px;
            height: 48px;
            padding-left: 26px;
            span {
              display: inline-block;
              width: 36px;
              height: 48px;
              line-height: 48px;
              text-align: center;
              background: url('~./image/income-wrap@2x.png') no-repeat;
              background-size: 100% 100%;
              color: #00fff4;
              font-family: 'PuHuiTiB';
              font-size: 22px;
              font-weight: 400;
              margin-right: 8px;
            }
          }
          .order-wrap {
            margin-top: 20px;
            display: flex;
            justify-content: space-evenly;
            .order-income {
              width: 160px;
              height: 89px;
              background: url('~../images/order-income@2x.png') no-repeat;
              background-size: 100% 100%;
              div {
                color: #ebe6e6;
                font-size: 12px;
                text-align: center;
                line-height: 38px;
              }
              .order-total {
                height: 52px;
                line-height: 52px;
                color: #00fff4;
                font-size: 22px;
                font-weight: 400;
                font-family: 'PuHuiTiB';
              }
            }
          }
        }
        // 节能减排
        &.cere-data {
          height: 211px;
          .wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            .co2,
            .trees {
              margin-top: 24px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
            .trees {
              margin-left: 74px;
            }
            .icon {
              width: 96px;
              height: 78px;
              background-size: 100% 100%;
            }
            .co2 .icon {
              background-image: url('./image/total-icon01@2x.png');
            }
            .trees .icon {
              background-image: url('./image/total-icon02@2x.png');
            }
            .value {
              margin-top: 16px;
              .num {
                font-size: 18px;
                font-weight: 400;
                color: #00fff4;
                line-height: 21px;
              }
              .unit {
                margin-left: 3px;
                font-size: 12px;
                font-weight: 400;
                color: #00fff4;
                line-height: 17px;
              }
            }
            .label {
              margin-top: 16px;
              height: 14px;
              font-size: 10px;
              font-weight: 400;
              color: #f2f2f2;
              line-height: 14px;
            }
          }
        }
        // 节能减排
        &.cere-data {
          height: 211px;
        }
        // 今日数据
        &.today-data {
          height: 180px;
          .wrap {
            margin-top: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 26px;
            .charge,
            .amount {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
            }
            .amount {
              margin-left: 16px;
            }
            .label {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .icon {
                margin-right: 4px;
                background-image: url('./image/ball@2x.png');
                width: 20px;
                height: 20px;
                background-size: 100% 100%;
              }
              .title {
                font-size: 14px;
                // font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
                line-height: 20px;
              }
            }
            .split-line {
              margin: 4px 0;
            }
            .charge .split-line {
              background-size: 100% 100%;
              height: 7px;
              width: 164px;
              background-image: url('./image/split-line-1@2x.png');
            }
            .amount .split-line {
              background-size: 100% 100%;
              height: 7px;
              width: 164px;
              background-image: url('./image/split-line-1@2x.png');
            }
            .value {
              font-size: 22px;
              font-weight: 400;
              color: #f7b500;
              line-height: 26px;
              background: linear-gradient(180deg, #ffffff 0%, #f3bf00 100%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
        // 昨日数据汇总
        &.yesterday-data {
          height: 366px;
          .compare-wrap {
            margin-top: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            .amount,
            .charge {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
            .charge {
              margin-left: 24px;
            }
            .icon {
              z-index: 1;
              width: 155px;
              height: 78px;
              background-image: url('~../images/yesterday-c-icon@2x.png');
              background-size: 100% 100%;
            }
            .compare,
            .value,
            .label {
              position: relative;
              z-index: 10;
              color: white;
            }
            .compare {
              margin-top: -44px;
              .text {
                font-size: 10px;
                font-weight: 500;
                color: #6cc8d7;
                line-height: 14px;
              }
              .compare-value {
                font-size: 12px;
                font-weight: 500;
                color: #fa6400;
                line-height: 17px;
              }
              &.less {
                .compare-value {
                  position: relative;
                  margin-left: 12px;
                  &::before {
                    position: absolute;
                    content: '';
                    height: 0px;
                    width: 0px;
                    left: -10px;
                    top: 6px;
                    border: 5px solid;
                    border-color: #fa6400 transparent transparent transparent;
                  }
                }
              }
              &.greater {
                .compare-value {
                  position: relative;
                  margin-left: 12px;
                  &::before {
                    position: absolute;
                    content: '';
                    height: 0px;
                    width: 0px;
                    left: -10px;
                    top: 6px;
                    border: 5px solid;
                    border-color: transparent transparent #fa6400 transparent;
                  }
                }
              }
            }
            .value {
              font-size: 20px;
              // font-family: AppleSystemUIFont;
              color: #00fff4;
              line-height: 28px;
              background: linear-gradient(180deg, #00faf4 0%, #008cff 100%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            .label {
              font-size: 10px;
              // font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              line-height: 14px;
            }
          }
          .rank-wrap {
            margin-left: 30px;
            margin-top: 4px;
            .top-rank {
              margin-top: 16px;
              display: flex;
              align-items: center;
              .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 25px;
                height: 25px;
                background-size: 100% 100%;
                background-image: url(./image/rank-icon@2x.png);

                margin-right: 14px;
                font-size: 14px;
                // font-family: AppleSystemUIFont;
                color: #ffffff;
                line-height: 18px;
              }
              .value {
                background-color: transparent;
                width: 292px;
                height: 22px;
                background-size: 100% 100%;
                background-image: url(./image/rank-value@2x.png);
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                .station {
                  font-size: 14px;
                  // font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #ffffff;
                  line-height: 20px;
                }
                .charge {
                  font-size: 14px;
                  // font-family: AppleSystemUIFont;
                  color: #ffffff;
                  line-height: 17px;
                }
              }
            }
          }
        }
        .total-wrap {
          width: 348px;
          height: 64px;
          background: url('~./image/total-wrap@2x.png') no-repeat;
          background-size: 100%;
          margin: 25px auto 0;
          display: flex;
          align-items: center;
          justify-content: center;
          .total-item {
            width: calc(50% - 74px);
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .subTitle {
              margin-top: 4px;
              margin-bottom: 2px;
              font-size: 13px;
              color: #fff;
            }
            .total {
              color: #00fff4;
              font-size: 22px;
              font-family: 'PuHuiTiB';
              font-weight: 400;
            }
          }
          .total-item:last-child {
            text-align: right;
          }
        }
      }
    }
    .overview-left {
      left: 0;
      padding-left: 110px;
    }
    .overview-right {
      right: 0;
      padding-right: 110px;
    }
    .overview-left::before,
    .overview-right::before {
      display: block;
      content: '';
      width: 300px;
      height: 100%;
      position: absolute;
      top: 0;
    }
    .overview-left::before {
      left: 0;
      background: url('~../images/overview-left@2x.png') no-repeat;
      background-size: 100% 100%;
    }
    .overview-right::before {
      right: 0;
      background: url('~../images/overview-right@2x.png') no-repeat;
      background-size: 100% 100%;
    }

    .overview-bottom {
      position: absolute;
      bottom: -20px;
      height: 150px;
      left: 666px;
      display: flex;
      justify-content: center;
      align-items: center;
      > div {
        width: 241px;
        height: 148px;
        background-size: 100% 100%;
        background-image: url(./image/bottom-bg@2x.png);
        &.order {
          margin-right: 105px;
        }
      }
    }
  }
</style>
