<template>
  <div id="container"></div>
</template>
<script>
  // import AMapLoader from '@amap/amap-jsapi-loader';
  import marker01 from '../images/marker-icon01@2x.png';
  // import marker02 from '../images/marker-icon02@2x.png';
  import marker03 from '../images/marker-icon03@2x.png';
  import popBg from './image/map-station-pop-bg@2x.png';

  const { AMap } = window;

  export default {
    props: {
      mapData: {
        type: Array,
        default: () => [],
      },
    },
    watch: {
      mapData: {
        immediate: false,
        deep: true,
        handler(val) {
          // eslint-disable-next-line no-unused-expressions
          val && this.setUpMarkers();
        },
      },
    },
    data() {
      return {
        amap: null,
        showMarkerIndex: 0,
      };
    },
    methods: {
      initMap() {
        this.amap = new AMap.Map('container', {
          // 初始化地图层级
          zoom: 11.5,
          zooms: [4.5, 20],
          // 初始化地图中心点
          center: [108.705117, 34.333439],
          pitch: 40,
          showLabel: true,
          // mapStyle: "amap://styles/darkblue",
          // 指定主题样式
          // mapStyle: 'amap://styles/885a20d3513fc7b9d850d952ae3f29a6',
          mapStyle: 'amap://styles/d68ce606afaba4b3b50371f20cce0231',
          // 地图模式 默认2D
          viewMode: '3D',
          rotateEnable: false,
          pitchEnable: false,
        });
        this.setUpMarkers();
      },
      setUpMarkers() {
        let marker = {};
        this.markers = [];
        const $this = this;
        this.amap.clearMap();
        this.mapData.forEach((item, index) => {
          marker = new AMap.Marker({
            position: [item.longitude, item.latitude],
            // eslint-disable-next-line no-undef
            offset: new AMap.Pixel(-30, -40),
            icon: marker01,
            clickable: true,
            map: this.amap,
          });
          marker.marksIndex = index;
          // eslint-disable-next-line func-names
          marker.on('click', function () {
            $this.markersUpdate($this.markers, item, this.marksIndex);
            {
              const {
                longitude: stationLongitude,
                latitude: stationLatitude,
                stationName,
                directAllCount,
                directleisure,
                exchangeAllCount,
                exchangeleisure,
              } = item || {};
              $this.infoWindow = new AMap.InfoWindow({
                anchor: 'bottom-center',
                // 弹窗偏移量
                offset: new AMap.Pixel(16, 6),
                content: `
                   <div id="markerContent" class="marker-content wrap" style="background-image: url('${popBg}')">
                        <div class="stationName">
                          <span>${stationName}</span><i class="el-icon-caret-right el-icon--right"></i>
                        </div>
                        <div class="row">
                            <span class="dc">直流</span>
                            <span class="value"><span class="amount">${directleisure}</span>空闲/${directAllCount}</span>
                        </div>
                        <div class="row">
                            <span class="ac">交流</span>
                            <span class="value"><span class="amount">${exchangeleisure}</span>空闲/${exchangeAllCount}</span>
                        </div>
                    </div>
                `,
              });
              // 打开弹窗体
              $this.infoWindow.open($this.amap, [
                parseFloat(stationLongitude),
                parseFloat(stationLatitude),
              ]);
              $this.addEvent(item, this.marksIndex);
            }
          });
          this.markers.push(marker);
          if (index === this.showMarkerIndex) {
            marker.emit('click', { target: marker });
            // const icon = item.emsType === '1' ? marker02 : marker03;
            const icon = marker03;
            this.markers[this.showMarkerIndex].setIcon(icon);
          }
        });
        this.amap.on('click', () => {
          if (this.infoWindow) {
            this.infoWindow.close();
            this.markersUpdate(this.markers);
          }
          // console.log(this.amap.getCenter(), this.amap.getZoom());
        });
      },
      /**
       * 更新markers icon
       */
      markersUpdate(markers, item, markersIndex) {
        markers.forEach((obj, i) => {
          if (obj.marksIndex === markersIndex) {
            // const icon = item.emsType === '1' ? marker02 : marker03;
            const icon = marker03;
            markers[i].setIcon(icon);
          } else {
            markers[i].setIcon(marker01);
          }
        });
      },
      /**
       * 注册点击事件
       */
      addEvent(item, index) {
        document.getElementById('markerContent').addEventListener('click', () => {
          // const { id, stationId, stationType } = item;
          // console.log(item);
          // if (stationType === '0') {
          //   this.$router.push({
          //     name: 'EnergyHome',
          //     query: {
          //       emsId: id,
          //       stationId,
          //     },
          //   });
          // } else {
          //   this.$router.push({
          //     name: 'ChargePlantDetails',
          //     query: {
          //       emsId: id,
          //       stationId,
          //     },
          //   });
          // }
          this.showMarkerIndex = index;
        });
        const iconsObj = document.getElementsByTagName('i');
        for (let i = 0; i < iconsObj.length; i++) {
          iconsObj[i].addEventListener('click', () => {
            const { stationId } = item;
            console.log('+++++stationId', stationId);
            this.$router.push({
              name: 'directConnectionPileList',
              query: {
                stationId,
              },
            });

            this.showMarkerIndex = index;
          });
        }
      },
    },
    created() {},
    mounted() {
      this.initMap();
    },
  };
</script>
<style lang="scss">
  #container .amap-logo,
  .amap-copyright {
    display: none !important;
  }
  #container {
    width: 100%;
    height: 100%;
    .amap-info-contentContainer.bottom-center {
      .amap-info-content {
        background: transparent;
        position: relative;
        top: -70px;
        overflow: inherit;
        .marker-content {
          width: 178px;
          height: 111px;
          cursor: pointer;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center;
          padding-left: 18px;
          .stationName {
            color: #fedb65;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            letter-spacing: 1px;
            padding-top: 16px;
            position: relative;
            .el-icon--right {
              position: absolute;
              top: 15px;
              right: 20px;
              font-size: 20px;
            }
          }
          .row {
            color: #fff;
            display: flex;
            align-items: center;
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .dc {
              display: inline-block;
              color: #051d3c;
              font-size: 12px;
              background: linear-gradient(180deg, #0efbe1, #06f6c0);
              border-radius: 2px;
              padding: 1px 6px;
              margin-right: 8px;
              margin-top: 14px;
            }
            .ac {
              margin-top: 11px;
              display: inline-block;
              color: #051d3c;
              font-size: 12px;
              background: linear-gradient(180deg, #9ae6ff 0%, #61c9ff 100%);
              border-radius: 2px;
              padding: 1px 6px;
              margin-right: 8px;
            }
            span {
              font-size: 12px;
              color: #fff;
              &.value {
                padding-top: 10px;
              }
              &.amount {
                font-size: 20px;
                color: #ffffff;
                line-height: 23px;
                margin-right: 4px;
              }
            }
          }
        }
        .marker-content::before {
          display: block;
          position: absolute;
          left: 50%;
          margin-left: -2.5px;
          bottom: -80px;
          content: '';
          width: 5px;
          height: 80px;
          background: url('~../images/marker-icon-b01.png') no-repeat;
          background-size: 100%;
        }
        // .wrap-2::before {
        //   background: url('~../images/marker-icon-b01.png') no-repeat;
        //   background-size: 100%;
        // }
        .amap-info-close {
          display: none;
        }
      }
      .amap-info-sharp,
      .amap-info-sharp:after {
        display: none;
      }
    }
    .amap-markers {
      .amap-marker {
        width: 92px;
        height: 92px;
        .amap-icon {
          width: 92px;
          height: 92px;
          img {
            width: 92px;
            height: 92px;
            user-select: none;
          }
        }
      }
    }
    .amap-maps {
      background: #0e0339;
    }
  }
</style>
