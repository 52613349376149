<template>
  <div id="LineCharts">
    <div v-if="!isShowLoad" id="charts-bar"></div>
    <div v-else class="bar-loading">
      <div class="ykc-loading bar-loading">
        <div class="k-line k-line11-1"></div>
        <div class="k-line k-line11-2"></div>
        <div class="k-line k-line11-3"></div>
        <div class="k-line k-line11-4"></div>
        <div class="k-line k-line11-5"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as echarts from 'echarts';

  export default {
    name: 'YkcLineCharts',
    props: {
      // 图表数据-服务端提供
      data: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        isShowLoad: true,
      };
    },
    watch: {
      data: {
        // 首次绑定是否执行handle
        immediate: true,
        deep: true,
        handler(val) {
          this.isShowLoad = false;
          this.$nextTick(() => {
            if (val.length > 0) {
              this.drawBar();
            }
          });
        },
      },
    },
    mounted() {
      this.$nextTick(() => {
        // 在div发生改变时调整echarts的尺寸
        this.myObserver = new ResizeObserver(() => {
          setTimeout(() => {
            if (this.myChart) {
              this.myChart.resize();
            }
          });
        });
        this.myObserver.observe(document.getElementById('LineCharts'));
      });
    },
    created() {},
    methods: {
      /**
       * 初始化图表
       */
      drawBar() {
        // 基于准备好的dom，初始化echarts实例
        const myChart = echarts.init(document.getElementById('LineCharts'));
        const option = {
          tooltip: {
            show: true,
            trigger: 'axis',
            axisPointer: {
              lineStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(0, 255, 233,0)',
                    },
                    {
                      offset: 0.5,
                      color: 'rgba(255, 255, 255,1)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(0, 255, 233,0)',
                    },
                  ],
                  global: false,
                },
              },
            },
            backgroundColor: 'rgba(50,50,50,0)',
            borderWidth: 0,
            padding: 0,
            extraCssText: 'box-shadow: none;',
            position(point, params, dom, rect, size) {
              // 显示位置
              return [point[0] - 5, point[1] - size.contentSize[1] + 5];
            },
            formatter(params) {
              // console.log('params==', params);
              if (!params || params.length === 0) {
                return '';
              }
              let html = `<div class="tbox">
                <div class="box">
                <div class="text">${params[0].name}</div>
                <div class="text text1 icon1">${params[0].seriesName}&nbsp;&nbsp;${params[0].value}度</div>
              `;
              if (params[1]) {
                html += `<div class="text text1 icon2">${params[1].seriesName}&nbsp;&nbsp;${params[1].value}元</div>`;
              }
              html += '</div></div>';
              return html;
            },
          },
          legend: [
            {
              itemWidth: 6,
              itemHeight: 6,
              bottom: -3,
              itemGap: 20,
              icon: 'circle',
              textStyle: {
                color: '#fff',
                fontSize: 9,
              },
              left: 'center',
              data: [
                {
                  name: '充电量',
                  textStyle: {
                    color: '#fff',
                    fontSize: 9,
                  },
                  itemStyle: {
                    color: '#26B8FF',
                    borderColor: '#26B8FF',
                    borderWidth: 2,
                    shadowColor: 'rgba(0, 0, 0, .3)',
                  },
                },
                {
                  name: '收入金额',
                  textStyle: {
                    color: '#fff',
                    fontSize: 9,
                  },
                  itemStyle: {
                    color: '#ff8c32',
                    borderColor: '#ff8c32',
                    borderWidth: 2,
                    shadowColor: 'rgba(0, 0, 0, .3)',
                  },
                },
              ],
            },
          ],
          grid: {
            top: '15%',
            left: '13%',
            right: '13%',
            bottom: '22%',
            containLabel: false,
          },
          xAxis: [
            {
              type: 'category',
              data: this.data.map(item => {
                return item.date;
              }),
              axisLine: {
                show: false,
                lineStyle: {
                  color: 'red',
                },
              },
              splitArea: {
                color: '#f00',
                lineStyle: {
                  color: '#f00',
                },
              },
              axisLabel: {
                fontSize: '9',
                color: '#fff',
                margin: 15,
                formatter: value => {
                  return value.substring(5, value.length);
                },
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(255,255,255,0.15)',
                },
              },
              axisTick: {
                show: false,
              },
              boundaryGap: false,
            },
          ],

          yAxis: [
            {
              type: 'value',
              name: '充电量',
              nameTextStyle: {
                align: 'center',
                color: '#fff',
                fontSize: 9,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(255,255,255,0.15)',
                },
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: '#fff',
                  fontSize: 9,
                },
              },
              splitLine: {
                show: false,
                lineStyle: {
                  type: 'dashed',
                  color: 'red',
                },
              },
            },
            {
              type: 'value',
              name: '收入金额',
              nameTextStyle: {
                align: 'center',
                color: '#fff',
                fontSize: 9,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(255,255,255,0.15)',
                },
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: '#d1e6eb',
                  fontSize: 9,
                },
              },
              splitLine: {
                show: false,
                lineStyle: {
                  type: 'dashed',
                  color: '#39608F',
                },
              },
            },
          ],
          series: [
            {
              name: '充电量',
              type: 'line',
              yAxisIndex: 0,
              smooth: true,
              showAllSymbol: true,
              symbol: 'circle',
              symbolSize: 6,
              lineStyle: {
                normal: {
                  color: '#73C9FF',
                  shadowColor: 'rgba(0, 0, 0, .3)',
                },
              },
              label: {
                show: false,
              },
              itemStyle: {
                color: '#020E28',
                borderColor: '#26B8FF',
                borderWidth: 4,
                shadowColor: 'rgba(0, 0, 0, .3)',
              },
              data: this.data.map(item => {
                return item.chargedPower;
              }),
            },
            {
              name: '收入金额',
              type: 'line',
              yAxisIndex: 1,
              smooth: true,
              showAllSymbol: true,
              symbol: 'circle',
              symbolSize: 6,
              lineStyle: {
                normal: {
                  color: '#FF8C32',
                  shadowColor: 'rgba(0, 0, 0, .3)',
                },
              },
              label: {
                show: false,
              },
              itemStyle: {
                color: '#020E28',
                borderColor: '#FF8C32',
                borderWidth: 4,
              },
              data: this.data.map(item => {
                return item.incomeAmount;
              }),
            },
          ],
        };
        // 绘制图表
        myChart.setOption(option);
        this.myChart = myChart;
      },
    },
    deactivated() {
      console.log('销毁LineCharts');
      if (this.myObserver && document.getElementById('LineCharts')) {
        this.myObserver.unobserve(document.getElementById('LineCharts'));
      }
      if (this.myChart) {
        this.myChart.dispose();
      }
    },
    beforeDestroy() {
      if (this.myObserver && document.getElementById('LineCharts')) {
        this.myObserver.unobserve(document.getElementById('LineCharts'));
      }
      if (this.myChart) {
        this.myChart.dispose();
      }
    },
  };
</script>
<style lang="scss">
  #LineCharts {
    width: 100%;
    height: 245px;
    .bar-loading {
      margin-top: 60px;
    }

    .tbox {
      position: relative;
      width: 115px;
      height: 81px;
      background-image: url('../images/plantDetails/line-dialog@2x.png');
      background-repeat: no-repeat;
      background-size: 100%;
      border: none;
      color: #fff;

      .box {
        position: relative;
        top: 4px;
        left: -8px;
      }
      .text {
        position: relative;
        font-size: 18px;
        transform: scale(0.5);
        margin-top: -8px;
      }
      .text1 {
        margin-left: 10px;
      }
      .icon1::before {
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        top: 7px;
        left: -16px;
        border-radius: 10px;
        background-color: #29aaff;
      }
      .icon2::before {
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        top: 7px;
        left: -16px;
        border-radius: 10px;
        background-color: #ff8c32;
      }
    }
  }
</style>
