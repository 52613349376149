<template>
  <el-header class="ykc-emp-header" :class="[`ykc-emp-header-${type}`]" :height="height">
    <div class="overview-close" @click="handleCloseClick"></div>
    <div class="ykc-emp-header-left">
      <div class="prepend"><slot name="prepend"></slot></div>
      <div class="title">{{ title }}</div>
    </div>
    <div class="ykc-emp-header-right">
      <slot name="append"></slot>
    </div>
  </el-header>
</template>

<script>
  import windowResizeMixin from '@/components/ykc-ui/utils/window-resize-mixin';

  export default {
    name: 'EmpHeader',
    mixins: [windowResizeMixin],
    // components: { YkcIcon },
    props: {
      // 左侧标题
      title: {
        type: String,
      },
      // 当前路由
      router: {
        type: String,
      },
      overviewVisible: Boolean,
    },
    data() {
      return {
        expandHeight: '80px',
        collapseHeight: '80px',
        height: '80px',
      };
    },
    computed: {
      isShow() {
        const [bg] = ['Overview'].filter(item => item === this.router);
        return bg || false;
      },
    },
    methods: {
      /**
       * @description 根据window resize计算大小
       */
      calculateSize(isExpanded) {
        this.height = isExpanded ? this.expandHeight : this.collapseHeight;
      },
      /**
       * @description 用户名下拉项点击
       */
      handleCommand(command) {
        this.$emit(command);
      },
      /**
       * @description 退出登录
       */
      handleLogout() {
        this.$emit('logout');
      },
      handleCloseClick() {
        this.$router.push('/');
      },
    },
  };
</script>

<style lang="scss">
  @import '~@/components/ykc-ui/styles/var.scss';
  .ykc-emp-header {
    &.el-header {
      // position: fixed;
      // top: 0;
      // left: 0;
      position: relative;
      display: flex;
      background-color: rgb(1, 25, 64);
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      min-width: 1366px;
      background-image: url('./image/header@2x.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      padding: 0;
      z-index: 3;
      .overview-close {
        position: absolute;
        right: 40px;
        top: 40px;
        height: 25px;
        width: 25px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        background-image: url('./image/close.png');
      }
    }
    &-left,
    &-right {
      padding-bottom: 6px;
      .header-item-wrap {
        display: flex;
        align-items: center;
        cursor: pointer;
        .el-dropdown {
          .user-wrap {
            display: inline-flex;
            align-items: center;
            .username {
              padding-left: 5px;
            }
          }
        }
        span {
          color: #027aff;
          font-size: 12px;
          font-weight: Medium;
          user-select: none;
        }
        .icon-img {
          display: block;
          width: 36px;
          height: 36px;
          background-repeat: no-repeat;
          background-size: 100%;
        }
        .menu {
          width: 20px;
          height: 20px;
          margin-left: 24px;
        }
      }
    }
    &-left {
      .header-item-wrap {
        > span {
          padding-right: 7px;
        }
        .icon-img {
          background-image: url('../images/back@2x.png');
        }
      }
    }
    &-expand,
    &-collapse {
      transition: height 0.3s ease;
    }

    &-collapse {
      .ykc-emp-header-left {
        > .title {
          font-size: 14px;
        }
      }
    }

    &-left {
      display: flex;
      justify-content: center;
      align-items: center;
      > .prepend {
        margin-right: 5px;
      }
      > .title {
        font-size: 18px;
        transition: font-size 0.3s ease;
        color: $_000000;
      }
    }

    &-expand &-username {
      font-size: 16px;
    }
    &-collapse &-username {
      font-size: 12px;
    }

    &-overview {
      color: $_FFFFFF;
    }
    &-expand &-overview {
      padding: 11px 23px;
      font-size: 16px;
    }
    &-collapse &-overview {
      padding: 7px 15px;
      font-size: 14px;
    }

    &-logout {
      margin-left: 44px;
      font-weight: bold;
      cursor: pointer;
      color: $theme_primary;
    }
  }
  // .el-dropdown-menu,
  // .emp-header-dropdown {
  //   padding: 9px 0 0;
  //   border: 1px solid rgba(59, 62, 255, 0.8);
  //   background-color: transparent;
  //   left: initial !important;
  //   right: 32px;
  //   background-image: linear-gradient(
  //     180deg,
  //     rgba(5, 19, 81, 0.8) 2%,
  //     rgba(19, 191, 179, 0.8) 100%
  //   );
  //   opacity: 0.95;
  //   .el-dropdown-menu__item {
  //     color: #fff;
  //     font-size: 12px;
  //     line-height: normal;
  //     padding: 0 28.5px 8px;
  //   }
  //   .el-dropdown-menu__item:focus,
  //   .el-dropdown-menu__item:hover {
  //     color: #78a5ff;
  //     background: none;
  //   }
  // }
  // .emp-header-dropdown.el-popper[x-placement^='bottom'] .popper__arrow,
  // .emp-header-dropdown.el-popper[x-placement^='bottom'] .popper__arrow::after {
  //   color: #3b3eff;
  //   border-bottom-color: #3b3eff;
  // }
</style>
