<!-- 柱状图 -->
<template>
  <div class="charts-bar-box" id="charts-bar-box">
    <div v-if="!isShowLoad" id="charts-bar"></div>
    <div v-else class="bar-loading">
      <div class="ykc-loading bar-loading">
        <div class="k-line k-line11-1"></div>
        <div class="k-line k-line11-2"></div>
        <div class="k-line k-line11-3"></div>
        <div class="k-line k-line11-4"></div>
        <div class="k-line k-line11-5"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as echarts from 'echarts';

  export default {
    name: 'YkcChartsBar',
    props: {
      // 图表数据-服务端提供
      data: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        isShowLoad: true,
      };
    },
    watch: {
      data: {
        deep: true,
        handler(val) {
          console.log('this.isShowLoad=', this.isShowLoad);
          this.isShowLoad = false;
          this.$nextTick(() => {
            if (val.length > 0) {
              this.drawBar();
            }
          });
        },
      },
    },
    mounted() {
      this.$nextTick(() => {
        // 在div发生改变时调整echarts的尺寸
        this.myObserver = new ResizeObserver(() => {
          setTimeout(() => {
            if (this.myChart) {
              this.myChart.resize();
            }
          });
        });
        this.myObserver.observe(document.getElementById('charts-bar-box'));
      });
    },
    methods: {
      /**
       * 初始化图表
       */
      drawBar() {
        // 基于准备好的dom，初始化echarts实例
        const myChart = echarts.init(document.getElementById('charts-bar-box'));
        const option = {
          xAxis: {
            type: 'category',
            data: this.data.map(x => {
              return x.date;
            }),
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontSize: '9',
              color: '#fff',
              margin: 12,
              formatter: value => {
                return value.substring(5, value.length);
              },
            },
          },
          yAxis: {
            type: 'value',
            // splitNumber: 8,
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              fontSize: '9',
              color: '#fff',
            },
          },
          grid: {
            top: 10,
          },
          legend: {
            itemWidth: 6,
            itemHeight: 6,
            icon: 'circle',
            top: 226,
            itemGap: 20,
            textStyle: {
              color: '#fff',
              fontSize: 10,
            },
          },
          tooltip: {
            show: true,
            trigger: 'axis',
            backgroundColor: 'rgba(50,50,50,0)',
            borderWidth: 0,
            padding: 0,
            extraCssText: 'box-shadow: none;',
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: 'none',
            },
            position(point, params, dom, rect, size) {
              // 显示位置
              return [point[0] - 5, point[1] - size.contentSize[1] + 5];
            },
            formatter(params) {
              // console.log('params==', params);
              if (!params || params.length === 0) {
                return '';
              }
              let html = `<div class="tbox">
                <div class="box">
                <div class="text">${params[0].name}</div>
                <div class="text text1 icon1">${params[0].seriesName}&nbsp;&nbsp;${params[0].value}kW.h</div>`;
              if (params[1]) {
                html += `<div class="text text1 icon2">${params[1].seriesName}&nbsp;&nbsp;${params[1].value}kW.h</div>`;
              } else {
                html += '<div class="text text1 icon2">放电量:&nbsp;&nbsp;0kW.h</div>';
              }
              html += '</div></div>';
              return html;
            },
          },
          series: [
            {
              name: '充电量',
              data: this.data.map(x => {
                return x.charge;
              }),
              type: 'bar',
              barWidth: 8,
              barGap: '0%',
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                // 渐变色设置
                colorStops: [
                  {
                    offset: 0,
                    color: '#00AFFF',
                  },
                  {
                    offset: 1,
                    color: '#0077FF',
                  },
                ],
              },
            },
            {
              name: '放电量',
              data: this.data.map(x => {
                return x.discharge;
              }),
              type: 'bar',
              barWidth: 8,
              barGap: '0%',
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                // 渐变色设置
                colorStops: [
                  // 渐变色设置
                  {
                    offset: 0,
                    color: '#0EFDE3',
                  },
                  {
                    offset: 1,
                    color: '#06FAC3',
                  },
                ],
              },
            },
          ],
        };
        // 绘制图表
        myChart.setOption(option);
        this.myChart = myChart;
      },
    },
    beforeDestroy() {
      if (this.myObserver && document.getElementById('charts-bar-box')) {
        this.myObserver.unobserve(document.getElementById('charts-bar-box'));
      }
      if (this.myChart) {
        this.myChart.dispose();
      }
    },
  };
</script>
<style lang="scss">
  .charts-bar-box {
    width: 100%;
    height: 265px;
    .bar-loading {
      margin-top: 140px;
    }
    .tbox {
      position: relative;
      width: 115px;
      height: 81px;
      background-image: url('../images/home/bar-dialog.png');
      background-repeat: no-repeat;
      background-size: 100%;
      border: none;
      color: #fff;

      .box {
        position: relative;
        top: 4px;
        left: -8px;
      }
      .text {
        position: relative;
        font-size: 18px;
        transform: scale(0.5);
        margin-top: -8px;
      }
      .text1 {
        margin-left: 10px;
      }
      .icon1::before {
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        top: 7px;
        left: -16px;
        border-radius: 10px;
        background-color: #0077ff;
      }
      .icon2::before {
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        top: 7px;
        left: -16px;
        border-radius: 10px;
        background-color: #06fac3;
      }
    }
  }
</style>
