<template>
  <count-to
    :start-val="startValue"
    :end-val="Number(this.endVal || 0)"
    :duration="duration"
    :decimals="decimals"
    :separator="separator">
    <slot></slot>
  </count-to>
</template>

<script>
  import countTo from 'vue-count-to';

  export default {
    name: 'ykc-count-to',
    components: { countTo },
    props: {
      duration: {
        // 需要滚动的时间
        type: Number,
        default: 500,
      },
      decimals: {
        // 要显示的小数位数
        type: Number,
        default: 0,
      },
      // 分隔符
      separator: {
        type: String,
        default: ',',
      },
      // startVal: { // 初始值
      //     type: Number,
      //     default: 0
      // },
      endVal: {
        // 最终值
        type: [Number, String],
        default: 0,
      },
    },
    watch: {
      endVal(newVal, oldVal) {
        this.startValue = Number(oldVal || 0);
      },
    },
    data() {
      return {
        startValue: 0,
      };
    },
  };
</script>
